import { Input, Layout, Typography } from 'antd'
import styled from '@xstyled/styled-components'

const { Header: _Header } = Layout

export const Root = styled(_Header)`
	background: #f3f4f9;
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 84px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${({ $isScrolled }) => ($isScrolled ? '1px solid rgba(0, 0, 0, 0.08)' : 'none')};
	.ant-menu {
		flex: auto;
		justify-content: right;
		border-bottom: none;
	}
`

export const Title = styled(Typography.Title)`
	color: primary.7 !important;
	margin: 0 8px !important;
`

export const Search = styled(Input.Search)`
	max-width: 500px;
	border-radius: 16px;
`
