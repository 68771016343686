import React from 'react'

import Page from 'shared/components/page/Page'

const TermsPage = () => {
	return (
		<Page id="terms">
			<div className="bg-alice_blue text-center">
				<div className="max-w-3xl sm:py-16 py-8 layout">
					<div className="fonts-h2">Terms Of Service</div>
					<div className="mt-4 fonts-body sm:mb-16 mb-8 text-left">
						Welcome to Gnomon Digital! These terms and conditions ("Terms") govern your use of our website and services.
						By accessing or using our website, you agree to be bound by these Terms. If you do not agree with any part
						of these Terms, please refrain from using our website.
						<br />
						<br />
						1. Intellectual Property: All content on our website, including text, graphics, logos, and images, is the
						property of Gnomon Digital and protected by intellectual property laws. You may not use, reproduce, or
						distribute any content without our prior written consent. <br />
						<br />
						2. Use of Services: Our services are provided on an "as is" basis. You agree not to use our services for any
						unlawful or unauthorised purposes. We reserve the right to terminate your access to our services at any time
						without prior notice. <br />
						<br />
						3. Limitation of Liability: Gnomon Digital shall not be liable for any direct, indirect, incidental,
						consequential, or exemplary damages resulting from your use of our website or services. <br />
						<br />
						4. Governing Law: These Terms shall be governed by and construed in accordance with the laws of France. Any
						disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts in France.
					</div>
				</div>
			</div>
		</Page>
	)
}

export default TermsPage
