import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import React, { useLayoutEffect } from 'react'

import DisclosurePage from 'modules/DisclosurePage'
import HomePage from 'modules/home'
import PrivacyPolicyPage from 'modules/PrivacyPolicyPage'
import TermsPage from 'modules/TermsPage'

import ErrorBoundary from 'shared/components/error/ErrorBoundary'
import Layout from 'shared/components/layout/Layout'

const App = () => {
	const location = useLocation()

	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0)
	}, [location.pathname])

	return (
		<ErrorBoundary>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<HomePage />} />
					<Route path="terms" element={<TermsPage />} />
					<Route path="privacy_policy" element={<PrivacyPolicyPage />} />
					<Route path="disclosure" element={<DisclosurePage />} />
				</Route>
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		</ErrorBoundary>
	)
}

export default App
