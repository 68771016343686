import { Box } from '@chakra-ui/react'
import { Button, Checkbox, Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import { RiGithubFill, RiLinkedinBoxFill, RiTwitterFill } from 'react-icons/ri'
import React from 'react'

import * as Styled from 'shared/components/layout/__styles__/Footer.styles'

const Footer = () => {
	return (
		<Styled.Root className="sm:px-16 px-4 bg-[#F3F4F9] text-[#000000]">
			<div className="max-w-7xl layout pt-8" id="contact">
				<div className="grid sm:grid-cols-3 sm:gap-16 gap-8 sm:px-16 px-">
					<div className="sm:col-span-2 col-span-3 max-w-[564px]">
						<Form layout="vertical">
							<Form.Item label="Contact" name="contact" className="text-[#000000]">
								<Input placeholder="Email, phone number or any way to contact you" />
							</Form.Item>
							<Form.Item label="Message" name="message">
								<Input.TextArea rows={4} placeholder="Hi! We are Lookscout..." />
							</Form.Item>
							<Form.Item
								name="agreement"
								valuePropName="checked"
								rules={[
									{
										validator: (_, value) =>
											value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
									},
								]}
							>
								<Checkbox>
									<span className="text-[#000000]">I agree with Nebulas Cloud Privacy Policy</span>
								</Checkbox>
							</Form.Item>
							<Form.Item>
								<Button type="primary" size="large" htmlType="submit">
									Submit
								</Button>
							</Form.Item>
						</Form>
					</div>
					<div className="sm:col-span-1 col-span-3 space-y-8">
						<div className="max-w-[300px]">
							<div className="text-3xl font-medium mb-1">
								<span className="text-3xl text-[#5A47CD]">Nebulas</span> Cloud
							</div>
							<div>Transform your data innovation into business value</div>
						</div>
						<div className="space-y-2">
							<div className="font-medium">Contact Us</div>
							<a className="flex items-center space-x-2" href="mailto:contact@gnomondigital.com">
								<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M2.97656 16.7349C2.64323 16.7349 2.35156 16.6099 2.10156 16.3599C1.85156 16.1099 1.72656 15.8183 1.72656 15.4849V4.65161C1.72656 4.31828 1.85156 4.02661 2.10156 3.77661C2.35156 3.52661 2.64323 3.40161 2.97656 3.40161H17.1432C17.4766 3.40161 17.7682 3.52661 18.0182 3.77661C18.2682 4.02661 18.3932 4.31828 18.3932 4.65161V15.4849C18.3932 15.8183 18.2682 16.1099 18.0182 16.3599C17.7682 16.6099 17.4766 16.7349 17.1432 16.7349H2.97656ZM10.0599 10.4433L2.97656 5.79744V15.4849H17.1432V5.79744L10.0599 10.4433ZM10.0599 9.19328L17.0599 4.65161H3.08073L10.0599 9.19328ZM2.97656 5.79744V4.65161V15.4849V5.79744Z"
										fill="black"
									/>
								</svg>
								<span className="font-normal text-sm text-[#000000]">Send an email</span>
							</a>
							<a className="flex items-center space-x-2" href="tel:+33 06 25 53 67 95">
								<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M16.6211 17.5684C14.9267 17.5684 13.2426 17.1517 11.569 16.3184C9.8954 15.485 8.39193 14.4017 7.05859 13.0684C5.72526 11.735 4.64193 10.2316 3.80859 8.55794C2.97526 6.88433 2.55859 5.2003 2.55859 3.50586C2.55859 3.238 2.64788 3.01478 2.82645 2.83621C3.00502 2.65764 3.22823 2.56836 3.49609 2.56836H6.41276C6.6018 2.56836 6.77059 2.63433 6.91914 2.76628C7.06766 2.89822 7.16276 3.0753 7.20443 3.29753L7.76693 5.92253C7.7947 6.11697 7.79123 6.29405 7.75651 6.45378C7.72179 6.6135 7.64887 6.74892 7.53776 6.86003L5.45443 8.96419C6.2322 10.2559 7.10373 11.3809 8.06901 12.3392C9.03429 13.2975 10.128 14.11 11.3503 14.7767L13.3294 12.735C13.4683 12.5822 13.628 12.4746 13.8086 12.4121C13.9892 12.3496 14.1697 12.3392 14.3503 12.3809L16.8294 12.9225C17.0421 12.9694 17.2168 13.0749 17.3535 13.2389C17.4902 13.403 17.5586 13.5961 17.5586 13.8184V16.6309C17.5586 16.8987 17.4693 17.1219 17.2907 17.3005C17.1122 17.4791 16.889 17.5684 16.6211 17.5684ZM4.82943 7.81836L6.51693 6.11003L6.03776 3.81836H3.80859C3.80859 4.36003 3.89193 4.95378 4.05859 5.59961C4.22526 6.24544 4.4822 6.98503 4.82943 7.81836ZM12.5169 15.3809C13.0864 15.6447 13.7044 15.86 14.3711 16.0267C15.0378 16.1934 15.6836 16.2906 16.3086 16.3184V14.0892L14.1628 13.6517L12.5169 15.3809Z"
										fill="black"
									/>
								</svg>
								<span className="font-normal text-sm text-[#000000]">Reserve a phone call</span>
							</a>
						</div>
						<div className="space-y-2">
							<div className="font-medium">Find us on</div>
							<div className="space-x-2 flex items-center">
								<RiGithubFill className="text-[#000000] text-2xl" />
								<RiLinkedinBoxFill className="text-[#000000] text-2xl" />
								<RiTwitterFill className="text-[#000000] text-2xl" />
							</div>
						</div>
					</div>
				</div>
				<Box borderBottom="1px solid rgba(0, 0, 0, 0.3)" margin="24px 0px" />
				<div className="flex-row sm:flex items-center justify-between">
					<Link to="/">
						<div className="h-full flex">
							<span className="text-[#5A47CD] text-2xl font-bold">Nebulas</span>
						</div>
						<div className="text-xs text-[#000000] -mt-1">Cloud</div>
					</Link>
					<nav className="flex-row sm:flex space-x-8 font-medium text-[#000000] sm:space-y-0 space-y-4">
						<a href="/#home" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
							Home
						</a>
						<a href="/#products" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
							Products
						</a>
						<a href="/#who" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
							For Who
						</a>
						<a href="/#why" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
							Why Nebulas
						</a>
						<a href="/#contact" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
							Contact Us
						</a>
					</nav>
				</div>
				<div className="mt-8">
					<div className="grid grid-cols-10 sm:gap-8 gap-2 sm:text-center text-left">
						<div className="sm:col-span-4 col-span-10 text-xs text-[#00000099]">
							© 2023 GNOMON DIGITAL. All rights reserved.
						</div>
						<Link to="/terms" className="sm:col-span-2 col-span-5 text-xs text-[#00000099]">
							Terms of Service
						</Link>
						<Link to="/privacy_policy" className="sm:col-span-2 col-span-5 text-xs text-[#00000099]">
							Privacy Policy
						</Link>
						<Link to="/disclosure" className="sm:col-span-2 col-span-5 text-xs text-[#00000099]">
							Responsible Disclosure
						</Link>
					</div>
				</div>
			</div>
		</Styled.Root>
	)
}

export default Footer
