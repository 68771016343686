import { Button } from 'antd'
import { motion, useCycle } from 'framer-motion'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import * as Styled from 'shared/components/layout/__styles__/Header.styles'

const sidebar = {
	open: (height = 1000) => ({
		clipPath: `circle(${height * 2 + 200}px at 100% 40px)`,
		transition: { type: 'spring', stiffness: 20, restDelta: 2 },
	}),
	closed: {
		clipPath: 'circle(0px at 100% 40px)',
		transition: { delay: 0.2, type: 'spring', stiffness: 400, damping: 40 },
	},
}

const variants = {
	open: { transition: { staggerChildren: 0.07, delayChildren: 0.2 } },
	closed: { transition: { staggerChildren: 0.05, staggerDirection: -1 } },
}

const variantsItem = {
	open: { y: 0, opacity: 1, transition: { y: { stiffness: 1000, velocity: -100 } } },
	closed: { y: 50, opacity: 0, transition: { y: { stiffness: 1000 } } },
}

const Header = () => {
	const [isScrolled, setIsScrolled] = useState(false)

	const [isOpen, toggleOpen] = useCycle(false, true)
	const containerRef = useRef(null)
	const { height } = useDimensions(containerRef)

	useLayoutEffect(() => {
		const checkWindowScroll = () => {
			const scrollTop = document.documentElement.scrollTop
			setIsScrolled(scrollTop > 0)
		}

		window.addEventListener('scroll', checkWindowScroll)
		return () => {
			window.removeEventListener('scroll', checkWindowScroll)
		}
	}, [])

	return (
		<Styled.Root className="sm:px-16 px-4" role="banner" $isScrolled={isScrolled}>
			<div className="flex items-center w-full mx-auto justify-between max-w-7xl layout">
				<a href="/#home">
					<div className="h-full flex">
						<span className="text-[#5A47CD] text-2xl font-bold">Nebulas</span>
					</div>
					<div className="text-xs text-[#000000] -mt-2">Cloud</div>
				</a>

				<nav className="whitespace-nowrap sm:block hidden space-x-8 font-medium text-[#332C5C]">
					<a href="/#home" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
						Home
					</a>
					<a href="/#products" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
						Products
					</a>
					<a href="/#who" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
						For Who
					</a>
					<a href="/#why" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
						Why Nebulas
					</a>
					<a href="/#contact" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
						Contact Us
					</a>
				</nav>

				<motion.nav
					className={
						isOpen
							? 'sm:hidden block absolute top-0 left-0 bottom-0 w-screen h-screen'
							: 'sm:hidden block absolute top-0 left-0 bottom-0 w-screen h-[86px] pointer-events-none'
					}
					initial={false}
					animate={isOpen ? 'open' : 'closed'}
					custom={height}
					ref={containerRef}
				>
					<motion.div
						className={
							isOpen
								? 'absolute w-full bg-[#FAFBFC] top-0 left-0 bottom-0 pointer-events-auto'
								: 'absolute right-[-1000px] w-full bg-[#FAFBFC] top-0 left-0 bottom-0 pointer-events-none'
						}
						variants={sidebar}
					/>
					<motion.ul
						variants={variants}
						className={
							isOpen
								? 'absolute py-16 px-4 m-auto pointer-events-auto'
								: 'absolute right-[-1000px] py-16 px-4 m-auto pointer-events-none'
						}
					>
						<motion.li
							variants={variantsItem}
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.95 }}
							onClick={() => toggleOpen()}
						>
							<a href="/#home" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
								Home
							</a>
						</motion.li>
						<motion.li
							variants={variantsItem}
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.95 }}
							onClick={() => toggleOpen()}
						>
							<a href="/#products" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
								Products
							</a>
						</motion.li>
						<motion.li
							variants={variantsItem}
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.95 }}
							onClick={() => toggleOpen()}
						>
							<a href="/#who" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
								For Who
							</a>
						</motion.li>
						<motion.li
							variants={variantsItem}
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.95 }}
							onClick={() => toggleOpen()}
						>
							<a href="/#why" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
								Why Nebulas
							</a>
						</motion.li>
						<motion.li
							variants={variantsItem}
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.95 }}
							onClick={() => toggleOpen()}
						>
							<a href="/#contact" className={'text-[#332C5C] hover:text-[#332C5C] inline-flex items-center space-x-1'}>
								Contact Us
							</a>
						</motion.li>
					</motion.ul>
					<Button
						icon={
							<svg width="24" height="24" viewBox="0 0 24 24" className="ml-1 mt-1">
								<Path
									variants={{
										closed: { d: 'M 2 2.5 L 20 2.5' },
										open: { d: 'M 3 16.5 L 17 2.5' },
									}}
								/>
								<Path
									d="M 2 9.423 L 20 9.423"
									variants={{
										closed: { opacity: 1 },
										open: { opacity: 0 },
									}}
									transition={{ duration: 0.1 }}
								/>
								<Path
									variants={{
										closed: { d: 'M 2 16.346 L 20 16.346' },
										open: { d: 'M 3 2.5 L 17 16.346' },
									}}
								/>
							</svg>
						}
						shape="circle"
						size="large"
						className="absolute top-6 right-4 pointer-events-auto"
						type="text"
						onClick={() => toggleOpen()}
					/>
				</motion.nav>
			</div>
		</Styled.Root>
	)
}

export default Header

export const useDimensions = (ref) => {
	const dimensions = useRef({ width: 0, height: 0 })

	useEffect(() => {
		dimensions.current.width = ref.current.offsetWidth
		dimensions.current.height = ref.current.offsetHeight
	}, [ref])

	return dimensions.current
}

const Path = ({ variants, d, transition }) => (
	<motion.path
		fill="transparent"
		strokeWidth="3"
		stroke={'hsl(0, 0%, 18%)'}
		strokeLinecap="round"
		variants={variants}
		d={d}
		transition={transition}
	/>
)
